import React from 'react'
import "./ServiceWhy.css"

const ServiceWhyHead = () => {
  return (
    <div className='serviceWhyHeadContainer'>
      <h6>Why SCENT ?</h6>
      <p className='serviceWhyUnderline'></p>
    </div>
  )
}

export default ServiceWhyHead
