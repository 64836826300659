import React from 'react'
import "./StyleCrewPage.css"
const StyleCrewHead = () => {
  return (
    <div className='styleCrewHeadContainer'>
      <h1 className='styleCrewHead'>Style Crew</h1>
    </div>
  )
}

export default StyleCrewHead
