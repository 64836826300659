import React from 'react'
import OlaplexlandingImg from './OlaplexlandingImg';

const OlaplexLandingHead = () => {
  return (
    <div className='olaplexLandingHead'>
      <OlaplexlandingImg/>
    </div>
  )
}

export default OlaplexLandingHead
