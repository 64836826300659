import React from 'react'
import classicManicureImg from "./../../../Images/NailSalonInnerPages/Classic Manicure.png";

const ClassicManicureImg = () => {
  return (
    <div className="nailExtImg">
    <img src={classicManicureImg} alt="classicManicureImg" />
  </div>
  )
}

export default ClassicManicureImg
