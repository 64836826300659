import React from 'react'
import "./OlaplexLandingWork.css"

const OlaplexLandingWorkHead = () => {
  return (
    <div className="olaplexLandingWorkHead">
    <h2>Works</h2>
  </div>
  )
}

export default OlaplexLandingWorkHead
