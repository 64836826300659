import React from "react";
import BodyPolishImg from "./../../../../Images/SpaInnerPage/Body Polish.png";

const BodyPolishSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={BodyPolishImg} alt="BodyPolishImg" />
    </div>
  );
};

export default BodyPolishSpaImg;
