import React from "react";
import aromaSpaImg from "./../../../../Images/SpaInnerPage/Aroma Massage.png";

const AromaSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={aromaSpaImg} alt="aromaSpaImg" />
    </div>
  );
};

export default AromaSpaImg;
