import React from 'react'
import BodyWaxingJpImg from "./../../../Images/JP_Nagar/Waxing, Threding & D-Tan.png";

const BrazilWaxingServiceImg = () => {
  return (
    <div className="HairSalonJpImg">
    <img src={BodyWaxingJpImg} alt="BodyWaxingJpImg" />
  </div>
  )
}

export default BrazilWaxingServiceImg
