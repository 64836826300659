import React from "react";
import top10Banner from "./../../../../Images/Blog/Top 10 hairColor/Banner Image.png";
const Top10HairColorImg = () => {
  return (
    <div className="hairTrimImg">
      <img src={top10Banner} alt="top10Banner" loading='lazy'/>
    </div>
  );
};

export default Top10HairColorImg;
