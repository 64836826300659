import React from "react";
import scentMassageImg from "./../../../../Images/SpaInnerPage/Aroma Massage.png";

const ScentMassageSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={scentMassageImg} alt="scentMassageImg" />
    </div>
  );
};

export default ScentMassageSpaImg;
