import React from 'react'
import hairSpaImg from "./../../../../Images/HairSalonInnerPage/HAIR SPA SERVICES.png"
const HairSpaImg = () => {
  return (
    <div className="haircutImg">
      <img src={hairSpaImg} alt="hairSpaImg" />
    </div>
  )
}

export default HairSpaImg
