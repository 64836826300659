import React from 'react'
import organicPedicureImg from "./../../../Images/NailSalonInnerPages/Organic Pedicure.png";

const OrganicPedicureImg = () => {
  return (
    <div className="nailExtImg">
    <img src={organicPedicureImg} alt="organicPedicureImg" />
  </div>
  )
}

export default OrganicPedicureImg
