import React from "react";
import "./UpTownLocAbout.css";
const UpTownLocAbout = () => {
  return (
    <div className="uptownLocAboutContainer">
      <h1>Discover Luxury at Scent in Up Town Whitefield, Bangalore</h1>
      <p>
        <ul>
          <li>
            Nestled in the heart of Up Town Whitefield, Scent offers an
            unparalleled experience of luxury and relaxation. Our salon and spa
            cater to the diverse beauty and wellness needs of the vibrant
            community in Whitefield and its surrounding areas.
          </li>
          <li>
            At Scent, we are committed to providing top-notch services that
            leave our clients feeling refreshed, rejuvenated, and pampered. With
            a team of skilled professionals and a wide range of services, we aim
            to exceed the expectations of every visitor who walks through our
            doors.
          </li>
          <br />
          <li>
            <h3>Our Services</h3>
          </li>
          <li>
            <span>Hair Services:</span> Whether you're looking for a trendy
            haircut, a stylish hair color, or a luxurious hair spa treatment,
            our expert stylists at Scent have got you covered. From classic cuts
            to the latest trends, we offer a variety of hair services to suit
            your preferences.
          </li>
          <li>
            <span>Nail Services:</span> Treat yourself to a manicure and
            pedicure session at our nail salon, where our talented nail
            technicians will pamper your hands and feet to perfection. Choose
            from a range of nail extensions, nail art, and polish options for a
            flawless finish.
          </li>
          <li>
            <span>Spa Services:</span> Indulge in pure relaxation with our spa
            services, including body massages, body scrubs, and facials. Our spa
            treatments are designed to rejuvenate your mind, body, and soul,
            leaving you feeling completely refreshed and revitalized.
          </li>
          <li>
            <span>Waxing and Threading:</span> Say goodbye to unwanted hair with
            our waxing and threading services. Our skilled estheticians use
            gentle techniques to ensure a smooth and hair-free finish, leaving
            your skin feeling silky soft and smooth.
          </li>
          <li>
            <span> Eyelash Extensions:</span> Enhance your natural beauty with
            our eyelash extension services. Whether you prefer a natural look or
            a more dramatic effect, our lash technicians will create the perfect
            set of lashes to suit your style.
          </li>
          <br />
          <li>
            <h3>Why Choose Us?</h3>
          </li>
          <li>
            <span>Convenient Location:</span> Located in Up Town Whitefield, our
            salon and spa are easily accessible from all parts of Bangalore,
            making it convenient for you to indulge in some self-care.
          </li>
          <li>
            <span>Skilled Professionals:</span> Our team of experienced
            hairstylists, nail technicians, estheticians, and massage therapists
            are dedicated to providing high-quality services and personalized
            attention to each client.
          </li>
          <li>
            <span> Relaxing Ambiance:</span> Step into our salon and spa and
            experience a serene and tranquil atmosphere that will help you
            unwind and escape from the hustle and bustle of daily life.
          </li>
          <li>
            <span>Affordable Luxury:</span> At Scent , we believe that luxury
            should be accessible to everyone. That's why we offer competitive
            prices without compromising on quality.
          </li>
          <br />
          <li>
            <h3>Book Your Appointment Today!</h3>
          </li>
          <li>
            Ready to experience the ultimate in beauty and relaxation? Book your
            appointment at Scent in Up Town Whitefield, Bangalore, and treat
            yourself to a day of pampering and indulgence. We look forward to
            welcoming you to our salon and spa soon!
          </li>
        </ul>
      </p>
    </div>
  );
};

export default UpTownLocAbout;
