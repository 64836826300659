import React from "react";
import spaJakkurImg from "./../../../Images/JP_Nagar/Spa.png";

const SpaInJakkurImg = () => {
  return (
    <div className="hairSalonInJakkurImg">
      <img src={spaJakkurImg} alt="spaJpImg" loading="lazy" />
    </div>
  );
};

export default SpaInJakkurImg;
