import React from "react";
import footSpaImg from "./../../../../Images/SpaInnerPage/Foot Massage.png";

const FootSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={footSpaImg} alt="footSpaImg" />
    </div>
  );
};

export default FootSpaImg;
