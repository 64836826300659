import React from 'react'
import "./StyleCrewPage.css"
const StyleCrewImages = (props) => {
  return (
    <div className='styleCrewImagesWrapper'>
      <img src={props.img} alt="" />
    </div>
  )
}

export default StyleCrewImages
