import React from "react";
import FranchiseImg from "./../../../Images/Salonfranchise/franchise2.png";

const SalonFranchiseImg = () => {
  return (
    <div className="HairSalonJpImg">
      <img src={FranchiseImg} alt="spaJpImg" />
    </div>
  );
};

export default SalonFranchiseImg;
