import React from "react";
import organicManicureImg from "./../../../Images/NailSalonInnerPages/Organic Manicure.png";

const OrganicManicureImg = () => {
  return (
    <div className="nailExtImg">
      <img src={organicManicureImg} alt="organicManicureImg" />
    </div>
  );
};

export default OrganicManicureImg;
