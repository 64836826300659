import React from 'react'
import acneFacialImg from "./../../../../Images/FacialPage/Acne Cure Facial.png";

const AcneCureFacialImg = () => {
  return (
    <div className="facialExtImg">
    <img src={acneFacialImg} alt="acneFacialImg" />
  </div>
  )
}

export default AcneCureFacialImg
