import React from 'react'
import prebridalPackage from "./../../../../Images/ServicePage/Pre-Bridal Treatment package.png"
const PrebridalPackageImg = () => {
  return (
    <div className='bridalMakeUpPackageImg'>
    <img src={prebridalPackage} alt="bridal" />
  </div>
  )
}

export default PrebridalPackageImg
