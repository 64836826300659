import React from "react";
import candleManicure from "./../../../Images/NailSalonInnerPages/Candle Spa Manicure.png";

const CandleManicureImg = () => {
  return (
    <div className="nailExtImg">
      <img src={candleManicure} alt="candleManicure" />
    </div>
  );
};

export default CandleManicureImg;
