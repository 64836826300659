import React from 'react'
import "./HairTreatmentLandingHead.css"
import HairTreatmentLandingImg from './HairTreatmentLandingImg'
const HairTreatmentLandingHead = () => {
  return (
    <div className='hairTreatmentLandingHead'>
<HairTreatmentLandingImg/>
    </div>
  )
}

export default HairTreatmentLandingHead
