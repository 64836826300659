import React from "react";
import gelArt from "./../../../Images/NailSalonInnerPages/Gel Nail Art.png";

const GelNailArtPageImg = () => {
  return (
    <div className="nailExtImg">
      <img src={gelArt} alt="gelArt" />
    </div>
  );
};

export default GelNailArtPageImg;
