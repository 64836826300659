import React from 'react'
import neckImg from "./../../../../Images/BeautyInnerPages/Neck.png"

const NeckImg = () => {
  return (
    <div className="faceImgContainer">
    <img src={neckImg} alt="neckImg" />
  </div>
  )
}

export default NeckImg
