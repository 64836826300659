import React from "react";
import "./Pricing.css"

const PricingHead = () => {
  return (
    <div className="pricingHeadContainer">
      <h2> Pricing Policy</h2>
    </div>
  );
};

export default PricingHead;
