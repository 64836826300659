import React from "react";
import lashesJakkurImg from "./../../../Images/JP_Nagar/Eye Lashes.png";

const LashesInJakkurImg = () => {
  return (
    <div className="hairSalonInLavelleImg">
      <img src={lashesJakkurImg} alt="lashesJakkurImg" loading="lazy"/>
    </div>
  );
};

export default LashesInJakkurImg;
