import React from "react";

const OrganicManicureBenefits = () => {
  return (
    <div className="nailExtensionMaintain">
      <h5>Aftercare Tips for Your Organic Manicure</h5>
      <p>
        To keep your nails looking beautiful and your manicure lasting longer,
        follow these aftercare tips:
      </p>
      <ul>
        <li>
          <h6>Avoid Harsh Chemicals</h6>Protect your nails from harsh chemicals
          by wearing gloves when doing household chores.
        </li>
        <li>
          <h6>Moisturize Regularly </h6>Avoid using your nails as tools to open
          packages or perform other tasks that can cause chipping or breaking.
        </li>
        <li>
          <h6>Schedule Regular Manicures: </h6>Maintain the health and
          appearance of your nails by scheduling regular manicures at SCENT
          Salon.
        </li>
      </ul>
    </div>
  );
};

export default OrganicManicureBenefits;
