import React from "react";
import "./SahakarLocAbout.css";
const SahakarLocAbout = () => {
  return (
    <div className="sahakarLocAboutContainer">
      <h1>
        Salon & Spa Sahakar Nagar, Bangalore – Your Ultimate Beauty Destination!
      </h1>
      <p>
        <ul>
          <li>
            SCENT offers a wide range of premium services to pamper you from
            head to toe. Our experienced team provides exceptional haircuts,
            facials, massages, and more, ensuring that you leave feeling
            refreshed and rejuvenated every time.
          </li>
          <li>
            Located in Sahakar Nagar, Bangalore, our salon is the perfect
            retreat from the hustle and bustle of daily life. Step into our
            serene oasis and let us take care of all your beauty needs.
          </li>
          <li>
            At SCENT , we understand that every client is unique, which is why
            we tailor our services to suit your individual needs and
            preferences. Whether you're looking for a quick touch-up or a full
            day of pampering, our experienced team is here to make you look and
            feel your best.
          </li>
          <li>
            In addition to our exceptional services, SCENT also offers
            membership options for our loyal clients. With our membership
            program, you can enjoy exclusive benefits and discounts on our
            services, ensuring that you always get the best value for your
            money.
          </li>
          <li>
            When you visit SCENT , you can expect nothing but the highest level
            of professionalism and customer service. Our friendly and
            knowledgeable staff will make you feel right at home from the moment
            you walk through the door.
          </li>
          <li>
            So why wait? Treat yourself to the luxury and relaxation you deserve
            at SCENT in Sahakar Nagar, Bangalore. Book your appointment today
            and experience the difference for yourself.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default SahakarLocAbout;
