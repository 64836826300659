import React from 'react'
import spaDayBanner from "./../../../../../Images/Blog/How a Spa Day/Banner Image.png";

const SpaDayImg = () => {
  return (
    <div className="pediManicureImg">
    <img src={spaDayBanner} alt="spaDayBanner" loading='lazy'/>
    </div>
  )
}

export default SpaDayImg
