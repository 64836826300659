import React from "react";
import { Link } from "react-router-dom";
import NavBg from "./../../NavBg/NavBg";
import "../HairSalonLocation.css";
import Members from "../../HomePage/Members/Members";
import OurSalon from "../../HomePage/OurSalon/OurSalon";
import Partners from "../../HomePage/Partners/Partners";
import InstaFeed from "../../HomePage/InstaFeed/InstaFeed";
import HelpNearYou from "../../Help/HelpNearYou";
import TextLocReviews from "../../TextLocReviews/TextLocReviews";
import ServiceWhy from "../../MainServicePages/ServiceWhy/ServiceWhy";
import { Helmet } from "react-helmet";
import HairServiceImg from "../../HairServicePage/HairServiceImg/HairServiceImg";
import Navi1 from "../../HairServicePage/Navi1/Navi1";
import HairServiceCardContain from "../../HairServicePage/HairServiceCard/HairServiceCardContain";
import WhatsApp from "../../HomePage/WhatsApp/WhatsApp";
import LastComp from "../../LastComp/LastComp";
const HairSalonUptown = () => {
  return (
    <div className="hairSalonLocation">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Hair Salon in Uptown, Whitefield – Hair Cut & Styling, Colour & Hair
          Treatments
        </title>
        <meta
          name="description"
          content=" SCENT Hair Salon in Uptown, Whitefield – Hair Cut & Styling, Hair
          Colour, Hair Treatments & more services. Book an Appointment
          Today!"
        />
      </Helmet>
      <NavBg />
      <HairServiceImg />
      <Navi1 />
      <HairServiceCardContain />
      <div className="hairSalonLocationWrapper">
        <div className="hairSalonLocationContent">
          <div className="hairSalonLocationTitle">
            <h2>
              Hair Salon in Uptown, Whitefield – Hair Cut & Styling, Colour &
              Hair Treatments
            </h2>
          </div>
          <div className="hairSalonLocationDesp">
            <p>
              SCENT Hair Salon in Uptown, Whitefield – Hair Cut & Styling, Hair
              Colour, Hair Treatments & more services. Book an Appointment
              Today!
            </p>
          </div>
          <div className="hairSalonLocationWelcomeHead">
            <h3>
              Experience Premier Hair Care at SCENT in Uptown, Whitefield{" "}
            </h3>
          </div>
          <div className="hairSalonLocationWelcomePara">
            <p>
              {" "}
              Welcome to SCENT , the premier destination for top-notch hair care
              services in Uptown, Whitefield. Our salon is dedicated to offering
              exceptional hairstyling, coloring, and treatment services that
              cater to your individual needs. At{" "}
              <Link to={"/hair-salon-in-uptown-whitefield"}>
                SCENT Hair Salon
              </Link>
              , we believe that great hair is the cornerstone of a confident
              look, and our expert team is here to help you achieve your perfect
              style.
            </p>
          </div>
          <div className="hairSalonLocationService">
            <h3>Our Hair Salon Services</h3>
            <div className="hairSalonLocationServiceList">
              <ol>
                <li>
                  <span>Expert Haircuts and Styling:</span> Our team of highly
                  skilled hairstylists delivers precision haircuts and bespoke
                  styling that enhance your natural beauty. Whether you're
                  looking for a classic cut or the latest trend, we tailor each
                  style to suit your personality and lifestyle.
                </li>
                <li>
                  <span>Professional Hair Coloring: </span> Elevate your look
                  with our expert hair coloring services. Our colorists are
                  skilled in a variety of techniques, including balayage, ombre,
                  highlights, and full color. We use high-quality, salon-grade
                  products to ensure vibrant, long-lasting results that
                  complement your style.
                </li>
                <li>
                  <span>Advanced Hair Treatments:</span> Rejuvenate your hair
                  with our comprehensive range of treatments. From deep
                  conditioning and keratin treatments to protein and moisture
                  therapies, our treatments are designed to repair damage, add
                  shine, and enhance overall hair health.
                </li>
                <li>
                  <span>Bridal and Special Occasion Styling:</span> Make your
                  special day unforgettable with our bridal and special occasion
                  styling services. Our experts create stunning updos, elegant
                  styles, and intricate braids that make you look your best for
                  any event.
                </li>
                <li>
                  <span>Men's Grooming:</span>We offer specialized grooming
                  services for men, including precision haircuts, beard trims,
                  and styling. Our barbers provide stylish and sophisticated
                  looks that ensure you always look sharp and well-groomed.
                </li>
              </ol>
            </div>
          </div>
          <div className="hairSalonLocationWhy">
            <h3>Why Choose SCENT Hair Salon in Uptown, Whitefield?</h3>
            <div className="hairSalonLocationWhyList">
              <ol>
                <li>
                  <span>Highly Trained Stylists: </span>Our team comprises
                  experienced and passionate stylists who stay updated with the
                  latest trends and techniques. They are committed to delivering
                  the highest quality of hair care services.
                </li>
                <li>
                  <span>Personalized Experience:</span> We prioritize your needs
                  and preferences, offering personalized consultations to ensure
                  that every service is tailored to your individual style and
                  requirements.
                </li>
                <li>
                  <span>Premium Products: </span> At SCENT , we use only the
                  finest hair care products known for their superior performance
                  and gentle care. Our products are selected to provide the best
                  results while maintaining the health and integrity of your
                  hair.
                </li>
                <li>
                  <span>Relaxing Environment</span> Our salon is designed to be
                  a serene and welcoming space where you can unwind and enjoy
                  your beauty treatments. We aim to provide a relaxing and
                  rejuvenating experience for all our clients.
                </li>
                <li>
                  <span>Convenient Location:</span> Conveniently located in
                  Uptown, Whitefield, SCENT is easily accessible, making it an
                  ideal choice for quick touch-ups or extensive beauty
                  treatments.
                </li>
              </ol>
            </div>
          </div>
          <div className="hairSalonLocationBook">
            <h3>Book Your Appointment Today!</h3>
          </div>
          <div className="hairSalonLocationBookPara">
            <p>
              Ready to transform your hair? Book your appointment at{" "}
              <Link to={"/hair-salon-in-uptown-whitefield"}>
                SCENT Hair Salon in Uptown, Whitefield
              </Link>
              today. Whether you're seeking a chic haircut, a vibrant new color,
              or a restorative treatment, our expert team is here to help you
              achieve your hair goals.
            </p>
            <p>
              Visit SCENT and discover why we are the top choice for{" "}
              <Link to={"/hair-salon-in-uptown-whitefield"}>
                hair care in Uptown, Whitefield
              </Link>
              . We look forward to welcoming you and providing an exceptional
              salon experience.
            </p>
          </div>
        </div>
      </div>
      <Members />
      <OurSalon />
      <Partners />
      <InstaFeed />
      <HelpNearYou />
      <TextLocReviews />
      <ServiceWhy />
      <WhatsApp />
      <LastComp />
    </div>
  );
};

export default HairSalonUptown;
