import React from "react";
import sportSpaImg from "./../../../../Images/SpaInnerPage/Sportz Massage.png";

const SportsSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={sportSpaImg} alt="sportSpaImg" />
    </div>
  );
};

export default SportsSpaImg;
