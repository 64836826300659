import React from "react";
import thaiReflxo from "./../../../../Images/SpaInnerPage/Thai Reflexology.png";

const ThaiReflexoSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={thaiReflxo} alt="thaiReflxo" />
    </div>
  );
};

export default ThaiReflexoSpaImg;
