import React from 'react'
import "../NearMe.css"
import WomenSalonHead from './WomenSalonHead'
import CompWomenSalon from './CompWomenSalon'

const WomenSalon = () => {
  return (
    <div className='nearContainer'>
  <WomenSalonHead/>
  <CompWomenSalon/>

  </div>
  )
}

export default WomenSalon
