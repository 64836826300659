import React from 'react'
import antiAgeFacialImg from "./../../../../Images/FacialPage/Anti Ageing Facial.png";

const AntiAgeFacialImg = () => {
  return (
    <div className="facialExtImg">
      <img src={antiAgeFacialImg} alt="antiAgeFacialImg" />
    </div>
  )
}

export default AntiAgeFacialImg
