import React from "react";

const OrganicManicureProcess = () => {
  return (
    <div className="nailExtensionHeading">
      <h4>The SCENT  Organic Manicure Process</h4>
      <p>
        Here’s a detailed look at what you can expect during your Organic
        Manicure at SCENT :
      </p>
      <ul>
        <li>
          1. <span>Consultation:</span> Your experience begins with a
          consultation with one of our expert nail technicians. They will assess
          the condition of your nails and hands and discuss your preferences and
          any specific concerns you may have.
        </li>
        <li>
          2. <span> Nail Shaping: </span>We start by trimming and shaping your
          nails to your desired length and shape. This step ensures that your
          nails look neat and uniform.
        </li>
        <li>
          3. <span>Cuticle Care: </span> Our technician will carefully push back
          and trim your cuticles, removing any dead skin. This not only enhances
          the appearance of your nails but also promotes healthy growth.
        </li>
        <li>
          4. <span>Exfoliation: </span> We use a gentle, organic exfoliant to
          remove dead skin cells from your hands and forearms. This process
          leaves your skin feeling smooth and revitalized.
        </li>
        <li>
          5. <span>Hand Massage:</span> A relaxing hand massage is performed
          using a nourishing, organic lotion. This step helps to improve
          circulation, relieve stress, and hydrate your skin.
        </li>
        <li>
          6. <span>Polish Application:</span> Finally, we apply a base coat,
          followed by your choice of organic nail polish, and finish with a top
          coat to seal and protect your nails. You can choose from a wide range
          of colors to suit your style and mood.
        </li>
        <li>
          7. <span>Drying:</span> Your nails are then left to dry completely. We
          may use a quick-dry spray or UV lamp to speed up the process, ensuring
          your polish sets perfectly.
        </li>
      </ul>
    </div>
  );
};

export default OrganicManicureProcess;
