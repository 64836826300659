import React from "react";
import "./HairTreatmentLandingProgram.css";

const HairTreatmentHeadProgram = () => {
  return (
    <div className="hairTreatmentHeadProgram">
      <h2>Our Programs</h2>
      <p>Make a difference in the life of an individual...</p>
    </div>
  );
};

export default HairTreatmentHeadProgram;
