import React from "react";
import cutFileImg from "./../../../Images/NailSalonInnerPages/Cut & File.png";

const CutFileImg = () => {
  return (
    <div className="nailExtImg">
      <img src={cutFileImg} alt="cutFileImg" />
    </div>
  );
};

export default CutFileImg;
