import React from 'react'
import stoneSpaImg from "./../../../../Images/SpaInnerPage/Stone Therapy.png";

const StoneSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={stoneSpaImg} alt="stoneSpaImg" />
    </div>
  )
}

export default StoneSpaImg
