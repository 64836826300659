import React from 'react'
import membership from "./../../Images/Membership.png"
const MembershipImg = () => {
  return (
    <div className='membershipImgContainer'>
      <img src={membership} alt="" />
    </div>
  )
}

export default MembershipImg
