import React from 'react'
import BouncyHairBlog from "./../../../../Images/Blog/Hair Bouncy and Beautiful Curls During Monsoon.png";

const BouncyHairImg = () => {
  return (
    <div className='hairTrimImg'>
    <img src={BouncyHairBlog} alt="hairTrim" loading='lazy'/>
  </div>
  )
}

export default BouncyHairImg
