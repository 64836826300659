import React from "react";

const ThaiReflexoSpaWellness = () => {
  return (
    <div className="headSpaHeadContainer">
      <h2>A Holistic Approach to Wellness</h2>
      <p>
        At SCENT, Thai Reflexology Massage is part of a broader approach to
        wellness. The salon offers a wide range of treatments, including{" "}
        <a href="https://scentlifestyle.com/spa">body massages</a>,{" "}
        <a href="https://scentlifestyle.com/facial">facials</a>, and{" "}
        <a href="https://scentlifestyle.com/spa">spa therapies</a>, all designed
        to promote holistic well-being. This integrated approach ensures that
        clients can enjoy a comprehensive wellness experience, addressing both
        physical and mental health.
      </p>
    </div>
  );
};

export default ThaiReflexoSpaWellness;
