import React from 'react'
import whiteSecretFacialImg from "./../../../../Images/FacialPage/White Secret.png";

const WhiteSecretFacialImg = () => {
  return (
    <div className="facialExtImg">
    <img src={whiteSecretFacialImg} alt="whiteSecretFacialImg" />
  </div>
  )
}

export default WhiteSecretFacialImg
