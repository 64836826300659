import React from 'react'
import "./Shipping.css"
const ShippingHead = () => {
  return (
    <div className='shipHeadContainer'>
      <h2>Shipping</h2>
    </div>
  )
}

export default ShippingHead
