import React from "react";
import chromeImg from "./../../../Images/NailSalonInnerPages/Chrome Polish.png";

const ChromeGelImg = () => {
  return (
    <div className="nailExtImg">
      <img src={chromeImg} alt="chromeImg" />
    </div>
  );
};

export default ChromeGelImg;
