import React from 'react'
import beardImg from "./../../../../Images/HairSalonInnerPage/BEARD STYLING.png"

const BeardImg = () => {
  return (
    <div className="haircutImg">
      <img src={beardImg} alt="beardImg" />
    </div>
  )
}

export default BeardImg
