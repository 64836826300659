import React from "react";
import nailGelerationImg from "./../../../Images/NailSalonInnerPages/Geleration.png";

const NailGelerationImg = () => {
  return (
    <div className="nailExtImg">
      <img src={nailGelerationImg} alt="nailGelerationImg" />
    </div>
  );
};

export default NailGelerationImg;
