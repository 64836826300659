import React from "react";
import bridalJakkurImg from "./../../../Images/JP_Nagar/Bridal Makeup.png";

const BridalMakeupInJakkurImg = () => {
  return (
    <div className="hairSalonInJakkurImg">
      <img src={bridalJakkurImg} alt="bridalJakkurImg" />
    </div>
  );
};

export default BridalMakeupInJakkurImg;
