import React from 'react'
import "./Cancel.css"

const CancelHead = () => {
  return (
    <div className='cancelHeadContainer'>
      <h2>Cancellation Policy</h2>
    </div>
  )
}

export default CancelHead
