import React from "react";
import BodyWaxingJakkurImg from "./../../../Images/JP_Nagar/Waxing, Threding & D-Tan.png";

const BodyWaxingInJakkurImg = () => {
  return (
    <div className="hairSalonInJakkurImg">
      <img src={BodyWaxingJakkurImg} alt="BodyWaxingJakkurImg" loading="lazy"/>
    </div>
  );
};

export default BodyWaxingInJakkurImg;
