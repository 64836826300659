import React from "react";
import "./JakkuruLocAbout.css";
const JakkuruLocAbout = () => {
  return (
    <div className="jakkuruLocAboutContainer">
      <h1>Luxuriate in Serenity: Scent in Jakkur, Bangalore</h1>
      <p>
        <ul>
          <li>
            Indulge in a world of luxury and tranquility at Scent in Jakkur,
            Bangalore. Our salon offers a haven of relaxation where you can
            escape the stresses of everyday life and rejuvenate your mind, body,
            and soul. With a wide range of premium services and expert staff, we
            ensure that every visit leaves you feeling pampered and refreshed.
          </li>
          <li>
            Conveniently located in Jakkur, Bangalore, our salon provides a
            sanctuary away from the hustle and bustle of the city. Step into our
            elegant space and let our skilled professionals take care of all
            your beauty needs.
          </li>
          <li>
            At Scent, we offer a comprehensive range of services to cater to
            every aspect of your grooming and wellness needs. From haircuts and
            styling to facials, massages, manicures, pedicures, and more, we
            have everything you need to look and feel your best.
          </li>
          <li>
            Our experienced team of stylists, therapists, and technicians are
            dedicated to providing you with the highest quality service and
            personalized attention. Whether you're looking for a quick touch-up
            or a full day of pampering, we tailor each treatment to suit your
            individual preferences and requirements.
          </li>
          <li>
            In addition to our regular services, Scent also offers membership
            options for our loyal clients. With our membership program, you can
            enjoy exclusive benefits and discounts on our services, making it
            even easier to treat yourself to regular indulgence.
          </li>
          <li>
            When you visit Scent in Jakkur, Bangalore, you can expect a serene
            and welcoming atmosphere where you can relax and unwind. Our
            friendly staff will ensure that you feel comfortable and cared for
            throughout your visit, leaving you feeling completely refreshed and
            rejuvenated.
          </li>
          <li>
            So why wait? Treat yourself to the ultimate pampering experience at
            Scent in Jakkur, Bangalore. Book your appointment today and discover
            the true meaning of luxury and relaxation.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default JakkuruLocAbout;
