import React from 'react'

const ReturnHead = () => {
  return (
    <div className='returnHeadContainer'>
      <h2>Returns & Refund Policy</h2>
    </div>
  )
}

export default ReturnHead
