import React from 'react'
import bodyScrubImg from "./../../../../Images/SpaInnerPage/Body Scrub.png";
const BodyScrubSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
    <img src={bodyScrubImg} alt="bodyScrubImg" />
  </div>
  )
}

export default BodyScrubSpaImg
