import React from "react";
import ServicePageImg from "./ServicePageImg";
const ServicePage = () => {
  return (
    <div className="servicePageContainer">
     <ServicePageImg />
    </div>
  );
};

export default ServicePage;
