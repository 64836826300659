import React from 'react'
import "./Schw.css"
const SchwHead = () => {
  return (
    <div className='schwHeadContainer'>
      <h2>Schwarzkopf</h2>
      <div className="schwUnderline"></div>
    </div>
  )
}

export default SchwHead
