import React from "react";
import frenchGelerationImg from "./../../../Images/NailSalonInnerPages/French Geleration.png";

const FrenchGelerationImg = () => {
  return (
    <div className="nailExtImg">
      <img src={frenchGelerationImg} alt="frenchGelerationImg" />
    </div>
  );
};

export default FrenchGelerationImg;
