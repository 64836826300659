import React from 'react'
import thaiSpa from "./../../../../Images/SpaInnerPage/Thai Massage.png";

const ThaiMassageSpaImgs = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={thaiSpa} alt="headSpaImg" />
    </div>
  )
}

export default ThaiMassageSpaImgs
