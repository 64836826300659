import React from 'react'
import "./BridalMakeUpPackageImg.css"
import bridal from "./../../../../../Images/ServicePage/Bridal Makeup.png"
const BridalMakeUpPackageImg = () => {
  return (
    <div className='bridalMakeUpPackageImg'>
      <img src={bridal} alt="bridal" />
    </div>
  )
}

export default BridalMakeUpPackageImg
