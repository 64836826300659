import React from 'react'
import backImg from "./../../../../Images/BeautyInnerPages/Back.png"

const BackImg = () => {
  return (
    <div className="faceImgContainer">
    <img src={backImg} alt="backImg" />
  </div>
  )
}

export default BackImg
