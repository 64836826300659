import React from 'react'
import eyeProtectFacialImg from "./../../../../Images/FacialPage/Eye Protection Facial.png";

const EyeProtectFacialImg = () => {
  return (
    <div className="facialExtImg">
    <img src={eyeProtectFacialImg} alt="eyeProtectFacialImg" />
  </div>
  )
}

export default EyeProtectFacialImg
