import React from "react";
import armsImg from "./../../../../Images/BeautyInnerPages/Arms.png"
const ArmsImg = () => {
  return (
    <div className="faceImgContainer">
      <img src={armsImg} alt="armsImg" />
    </div>
  );
};

export default ArmsImg;
