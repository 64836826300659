import React from 'react'
import "./Payment.css"
const PaymentHead = () => {
  return (
    <div className='paymentHeadContainer'>
      <h2>Payment Modes</h2>
    </div>
  )
}

export default PaymentHead
