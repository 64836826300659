import React from "react";
import "./MicroBladingCompare.css"
const MicroBladingCompareHead = () => {
  return (
    <div className="microBladingCompareHead">
      <h2>Stunning Transformations: Before & After Images</h2>
    </div>
  );
};

export default MicroBladingCompareHead;
