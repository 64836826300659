import React from "react";
import hairColorImg from "./../../../Images/HairSalonInnerPage/HAIR COLOUR & HIGHLIGHTS SERVICES.png";
const HairColorImg = () => {
  return (
    <div className="haircutImg">
      <img src={hairColorImg} alt="hairColorImg" />
    </div>
  );
};

export default HairColorImg;
