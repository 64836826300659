import React from "react";
import ombreGelPolishImg from "./../../../Images/NailSalonInnerPages/Ombre Gel Polish.png";

const OmbreGelPolishImg = () => {
  return (
    <div className="nailExtImg">
      <img src={ombreGelPolishImg} alt="ombreGelPolishImg" />
    </div>
  );
};

export default OmbreGelPolishImg;
