import React from "react";
import baliSpaImg from "./../../../../Images/SpaInnerPage/Balinese Massage.png";

const BalineseseSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
      <img src={baliSpaImg} alt="baliSpaImg" />
    </div>
  );
};

export default BalineseseSpaImg;
