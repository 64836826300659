import React from 'react'
import backSpaImg from "./../../../../Images/SpaInnerPage/Back Massage.png";

const BackSpaImg = () => {
  return (
    <div className="headSpaImgContainer">
    <img src={backSpaImg} alt="backSpaImg" />
  </div>
  )
}

export default BackSpaImg
