import React from 'react'
import goldFacialImg from "./../../../../Images/FacialPage/Gold Facial.png"

const GoldFacialImg = () => {
  return (
    <div className="facialExtImg">
      <img src={goldFacialImg} alt="goldFacialImg" />
    </div>
  )
}

export default GoldFacialImg
