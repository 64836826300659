import React from "react";
import nailExtImg from "./../../../../Images/NailSalonInnerPages/Nail Extension.png";
import "./NailExtensionImg.css"
const NailExtensionImg = () => {
  return (
    <div className="nailExtImg">
      <img src={nailExtImg} alt="nailExtImg" />
    </div>
  );
};

export default NailExtensionImg;
