import React from "react";
import "./Services.css";

const ServiceHead = () => {
  return (
    <div className="serviceContent" id="serviceContent1">
      <h2>
        Our <br />
        <span> Salon Services</span>
      </h2>
    </div>
  );
};

export default ServiceHead;
