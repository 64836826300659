import React from 'react'
import "./NavBg.css"
const NavBg = () => {
  return (
    <div className='navBg'>
      
    </div>
  )
}

export default NavBg
