import React from "react";
import "./HairTreatmentLandingMission.css";

const HairTreatmentLandingMissionCard = () => {
  return (
    <div className="hairTreatmentMissionCardWrapper">
      <div className="hairTreatmentLandingMissionCard">
        <img
          src="https://img.freepik.com/free-photo/woman-wearing-pink-hair-clip-side-view_23-2149872380.jpg?t=st=1723210773~exp=1723214373~hmac=dafda225902a7a9895f3a9b1bc7518b0f00757f7ab7ebd090196a7ff7a1cfa21&w=996"
          alt=""
        />
        <div className="imgBackFace">
          <h3>Hair Treatment</h3>
        </div>
      </div>
    </div>
  );
};

export default HairTreatmentLandingMissionCard;
